<template>
  <div class="confirm">
    <div>
      <div class="title">商品信息</div>
      <div class="item" v-for="(item, index) in items" :key="index">
        <div class="itemTitle">
          <div class="titles">{{ item.name }}</div>
        </div>
        <div class="itemCover">
          <img :src="item.coverUrl" alt="" />
        </div>
      </div>
    </div>
    <div>
      <div class="title">选择支付方式</div>
      <div class="subtitle">人民币支付</div>
      <el-radio-group v-model="payType" style="width: 100%">
        <div class="payType" @click="payType = 'alipay'">
          <img src="@/assets/img/pay/alipay.png" alt="" />
          <el-radio label="alipay"></el-radio>
        </div>
        <div class="payType" @click="payType = 'wechat'">
          <img src="@/assets/img/pay/wechat.png" alt="" />
          <el-radio label="wechat"></el-radio>
        </div>
        <div class="separator"></div>
        <div class="subtitle">美元支付</div>
        <div
          class="payType"
          @click="payType = 'paypal'"
          style="border-bottom: 1px solid #eeeeee"
        >
          <img src="@/assets/img/pay/paypal.png" alt="" />
          <el-radio label="paypal"></el-radio>
        </div>
      </el-radio-group>
    </div>
    <div class="confirmBtn">
      <div class="price">
        实付<span class="usdprice">{{
          payType == "paypal"
            ? "$" + orderData.payPalPrice
            : "￥" + orderData.rmbPayAmount
        }}</span>
      </div>
      <div class="buyNow" @click="buyNow">立即购买</div>
    </div>
    <Popup v-if="show" v-model="show" position="bottom">
      <div style="padding: 20px">
        <div id="paypal-button-render"></div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { navigateBack } from "@/utils/index";
import { getOrder, payOrder, paymentCheck } from "@/service/index";
import { Toast, Popup } from "vant";
import { loadScript } from "@paypal/paypal-js";
export default {
  name: "Order",
  components: { Popup },
  data() {
    return {
      items: [],
      discountPrice: "",
      totalPrice: "",
      orderData: {},
      payType: "wechat",
      show: false,
      checkPaymentTimer: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getOrder();
    },
    getOrder() {
      getOrder(this.$route.query.orderId).then((res) => {
        if (res.success) {
          this.orderData = res.result;
          this.items = res.result.courses;
          console.log(this.orderData);
        }
      });
    },
    buyNow() {
      clearInterval(this.checkPaymentTimer);
      if (this.payType == "paypal") {
        this.show = true;
        const that = this;
        this.$nextTick(() => {
          loadScript({
            "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID,
            vault: true,
          }).then((paypal) => {
            paypal
              .Buttons({
                createOrder: function (data, actions) {
                  return that._getOrder();
                },
                onApprove: function (data, actions) {
                  return that._paypalCallback(data.orderID);
                },
              })
              .render("#paypal-button-render");
          });
        });
      } else {
        this.checkPaymentTimer = setInterval(() => {
          this.paymentComplete();
        }, 2000);

        payOrder(
          this.payType,
          this.$route.query.orderId,
          this.payType == "wechat" ? { mobile: true } : {}
        )
          .then((res) => {
            if (res.success) {
              if (this.payType == "wechat") {
                window.location.href = res.result.content;
              } else if (this.payType == "alipay") {
                localStorage.setItem("payData", JSON.stringify(res.result));
                this.$router.push({
                  path: "/payform",
                  query: { orderId: this.$route.query.orderId },
                });
              }
            } else {
              Toast(res.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    /**
     * @description 获取订单编号
     */
    async _getOrder() {
      let curOrderId = this.$route.query.orderId; //获取订单ID
      let content;
      await fetch(
        process.env.VUE_APP_BASE_URL + "order/payment/paypal/" + curOrderId,
        {
          method: "POST",
          headers: {
            "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
          },
        }
      )
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          content = data.result.content;
        });
      return content;
    },
    async _paypalCallback(paypalOrderId) {
      let content;

      await fetch(
        process.env.VUE_APP_BASE_URL +
          "payment/callback/paypal?orderId=" +
          paypalOrderId,
        {
          method: "POST",
          headers: {
            "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
          },
        }
      )
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          content = data.result.content;
          this.$router.push("/pay/success");
        });
      return content;
    },
    paymentComplete() {
      paymentCheck(this.$route.query.orderId)
        .then((res) => {
          if (res.result) {
            this.$router.push("/pay/success");
          }
        })
        .catch((err) => {
          console.log("paymentCheck fail", err);
        });
    },
  },
  watch: {
    payType(val) {
      clearInterval(this.checkPaymentTimer);
    },
    $route() {
      clearInterval(this.checkPaymentTimer);
    },
  },
};
</script>
<style scoped lang="scss">
.confirm {
  margin-top: 54px;
  .title {
    padding: 12px 20px;
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #979797;
    line-height: 14px;
    border-bottom: 1px solid #eeeeee;
  }
  .subtitle {
    font-size: 12px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #979797;
    line-height: 12px;
    padding: 12px 20px;
  }
  .item {
    padding: 12px 20px;
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid #eeeeee;
    .itemTitle {
      width: 48%;
      margin-right: 2%;
      position: relative;
      .titles {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        line-height: 18px;
      }
    }
    .itemCover {
      width: 50%;
      img {
        width: 100%;
        vertical-align: top;
        border-radius: 4px;
      }
    }
  }
}
.confirmBtn {
  width: 100%;
  height: 56px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px -4px 4px 1px rgba(195, 195, 195, 0.2);
  display: flex;
  flex-wrap: nowrap;
  .price {
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    line-height: 14px;
    width: calc(100% - 150px);
    line-height: 56px;
    padding-left: 20px;
  }
  .usdprice {
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #f14141;
    margin-left: 6px;
  }
  .buyNow {
    background: linear-gradient(135deg, #ff7809 0%, #ffcf00 100%, #ff9568 100%);
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
    width: 130px;
    text-align: center;
  }
  .discount {
    font-size: 12px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #f14141;
    margin-left: 6px;
  }
}
.getcode {
  margin-left: 6px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #f14141;
  text-decoration: underline;
}
.payType {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  line-height: 40px;
  padding: 20px;
  img {
    height: 40px;
  }
}
::v-deep .el-radio__label {
  display: none !important;
}
::v-deep .el-radio {
  line-height: 40px;
}
.separator {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  height: 14px;
  width: 100%;
}
</style>